<template>
    <fragment>
        <div class="flex space-between margin-btm-24">
            <h2>SSN Audit</h2>
            <button class="custom-btn" :disabled="disableDownloadButton">
                <img src="@/assets/images/icons/download.png" @click="downloadSSNHistory" />
            </button>
            <!-- <div v-if="downloadError" class="download-error-div"><span  class="download-error">Error in csv data</span></div> -->
        </div>
        <data-table :entries="auditNotes" :headers="auditNotesHeader" :loading="loader">
            <template #date="{ item }"> {{ formatDate(item.changed_on) }} </template>
        </data-table>
    </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import { encodeWithParam,decrypt } from "@/filters";
// import { downloadFile } from "../../../../filters";
import { mapActions } from "vuex";

export default {
    name: "PartnerSsnAudit",
    components: {
        Fragment,
        DataTable,
    },
    data() {
        return {
            loader: true,
            auditNotes: [],
            disableDownloadButton: true,
            downloadError:false,
        };
    },
    props: {
      partner: {
        type: String,
        required: false,
      }
    },
    computed: {
        partnerID() {
            return this.$route.params.id;
        },
        auditNotesHeader() {
            return [
                new TableHeader({ label: "Date", headerClass: "cell15 text-left", name: "date", cellContents: "changed_on" }),
                new TableHeader({ label: "Changed from", headerClass: "cell15 text-left", name: "changed_from", cellContents: "changed_from" }),
                new TableHeader({ label: "Changed to", headerClass: "cell10 text-left", name: "changed_to", cellContents: "changed_to", cellClass: "capitalize" }),
                new TableHeader({ label: "Changed by", headerClass: "cell20 text-left", name: "modified_by", cellContents: "modified_by" }),
                new TableHeader({ label: "Description", headerClass: "cell30 text-left", name: "description", cellContents: "description" }),
            ]
        }
    },
    methods: {
        ...mapActions("getRole"),
        async getDriverSSNLogs() {
            this.loader = true;
            await restApi.post(`/partner/getPartnerSSNHistory`, encodeWithParam({ partnerID: this.partnerID, ispartner:this.$props.partner }))
                .then(async({ data }) => {
                    this.auditNotes = [];
                    const resp = data?.result ?? [];
                    for await (const record of resp){
                        record.changed_to = await decrypt(record.changed_to)  
                        record.changed_from = await decrypt(record.changed_from) 
                        this.auditNotes.push(record);
                    }

                    this.loader = false;
                    this.disableDownloadButton = false;
                }).catch(() => {
                    this.loader = false;
                })
        },
        async downloadSSNHistory() {
            this.disableDownloadButton = true;

            // restApi.post('/partner/downloadSSNHistory', encodeWithParam({ partnerID: this.partnerID })).then((data) => {
            //     downloadFile(data.data, `SSN Audit.csv`);
            //     this.disableDownloadButton = false;
            // })

            const csvdata=JSON.parse(JSON.stringify(this.auditNotes))
            csvdata.forEach(async record => {
                      record.changed_on=DateTime.fromISO(record.changed_on).setZone("America/Los_Angeles").toFormat("LL/dd/yyyy hh:mm:ss a ").toString()
            });
        if(this.auditNotes.length>0){
            const delimiter = ',';
            const jsonToCSV = (json) => {
                const keys = Object.keys(json[0]);
                const csvContent = keys.join(delimiter) + '\n' + json.map(row => keys.map(key => row[key]).join(delimiter)).join('\n');
            return csvContent;
        };
        const csvContent = await jsonToCSV(csvdata);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'SSN_Audit.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.disableDownloadButton = false;
      } else {
        this.downloadError=true;
        // this.disableDownloadButton = false;
      }
    }
        },
        formatDate(date) {
            return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("hh:mm a, LL/dd/yyyy");
        },
        
    },
    mounted() {
        this.getDriverSSNLogs();
    }
};
</script>
<style scoped>
.capitalize {
    text-transform: capitalize;
}
.custom-btn {
    padding: 9px 10px;
    border-radius: 4px;
    border: 1px solid #CCC;
    height: 40px;
    cursor: pointer;
    background-color: #FFF;
}
.download-error{
    color: #e71e1e;
    font-size: 16px;
    font-family: 'Rubik'
}
</style>
