<template>
  <fragment>
    <div style="display: flex;">
      <h2 style="flex: 50%;">Deposit Summaries</h2>
      <div style="margin-bottom: 20px;">
        <button-bar :buttons="paymentProcessorButtons" @onSelect="buttonSelected" /> 
      </div>
      
     <img  style="height: 30%;margin-left: 15px;width: 3%;margin-top: 8px; cursor: pointer;" v-if="branchButtonSelected && getPermission('dwnldDeposits')" @click="multiDownload" src="@/assets/images/logos/download.svg"/>
     <img  style="height: 30%;margin-left: 15px;width: 3%;margin-top: 8px; cursor: pointer;" v-if="oneButtonSelected && !oneDailyBreakup && getPermission('dwnldDeposits')" @click="oneMultiDownload" src="@/assets/images/logos/download.svg"/>
     <img  style="height: 30%;margin-left: 15px;width: 3%;margin-top: 8px; cursor: pointer;" v-if="achButtonSelected && getPermission('dwnldDeposits')" @click="achMultiDownload" src="@/assets/images/logos/download.svg"/>
    </div>
    <div class="annualEarnings">
    <div class="earningsAmount">{{ totalEarnings  | formatMoney("s")  }}</div>
    <div class="earningsText">
      <ul>
        <li class="earnings-instructions">Total Earnings</li>
        <li class="earnings-instructions">(as of Tax Year 2023)</li>
      </ul>
    </div>
    </div>

    <div v-if="branchButtonSelected && !oneDailyBreakup">
      <data-table :entries="depositSummaries" :headers="depositSummaryHeaders" :loading="loader">
        <template #transactionPeriod="{ item }"><input type="checkbox" class="checkbox-input-deposit" @click="multiSelect(item, $event)"> {{ payPeriod(item) }}</template>
        <template #source="{ item }"> {{ source(item.disbursement_src) }}</template>
        <template #net="{ item }"> {{ item.totalAmount | formatMoney("s") }}</template>
        <template #disbursementDate="{ item }"> {{ formatDate(item.disbursement_date) }}</template>
        
        <!-- <template #gross="{ item }"> {{ grossTotal(item) | formatMoney("s") }}</template> -->
        
        <template #actions="{ item }"><a @click="openFlyout(item)" @click.prevent>View</a></template>
      </data-table>
    </div>


    <div v-if="oneButtonSelected && !oneDailyBreakup">
      <data-table :entries="oneDepositSummaries" :headers="depositSummaryOldHeaders" :loading="loader">
        <template #source="{ item }">{{ source(item.disbursement_src)  }}</template>
        <template #payPeriod="{ item }"> <input type="checkbox" class="checkbox-input-deposit" @click="oneMultiSelect(item, $event)"> {{ payPeriodOne(item) }}</template>
        <template #net="{ item }"> {{ item.amount | formatMoney("s") }}</template>
        <template #status="{ item }"> {{ item.disbursement_src === "ADHOC" ? "Processed - Adhoc" : "Disbursed" }} </template>
        <template #actions="{ item }"><a @click="oneBreakup(item)" @click.prevent>View</a></template>
      </data-table>
    </div>

      <div v-if="achButtonSelected && !oneDailyBreakup">
      <data-table :entries="achDepositSummaries" :headers="depositSummaryHeaders" :loading="loader">
        <template #transactionPeriod="{ item }"><input type="checkbox" class="checkbox-input-deposit" @click="achMultiSelect(item, $event)"> {{ payPeriodOne(item) }}</template>
        <template #source="{ item }"> {{ source(item.disbursement_src)  }}</template>
        <template #net="{ item }"> {{ item.amount | formatMoney("s") }}</template>
        <template #status="{ item }">
          <span :title="item.pay_type == 'ADHOC' ? getTooltipData(item) : ''">
            {{ item.status }}
          </span>
        </template>
        <template #disbursementDate="{ item }"> {{ formatDate(item.disbursement_date) }}</template>
        <template #actions="{ item }"><a @click="openAchFlyout(item)" @click.prevent>View</a></template>
      </data-table>
    </div>

    <div v-if="holdButtonSelected && !oneDailyBreakup">
      <data-table :entries="holdDepositSummaries" :headers="depositSummaryOldHeaders" :loading="loader">
        <template #source="{ item }"> {{ source(item.disbursement_src) }}</template>
        <template #payPeriod="{ item }"> {{ payPeriodOne(item) }}</template>
        <template #net="{ item }"> {{ item.amount | formatMoney("s") }}</template>
        <template #status="{ item }">
          <span :title="item.pay_type == 'ADHOC' ? getTooltipData(item) : ''">
            {{ (item.disbursement_src === "ADHOC" && item.status.toUpperCase() !== 'CREATED') ? `ADHOC - ${item.status}` : item.status }}
          </span>
        </template>
        <template #actions="{ item }"><a @click="openHoldFlyout(item)" @click.prevent>View</a></template>
      </data-table>
    </div>

    <div v-if="checkButtonSelected && !oneDailyBreakup">
      <data-table :entries="checkDepositSummaries" :headers="depositSummaryHeaders" :loading="loader">
        <template #source="{ item }"> {{ source(item.disbursement_src)  }}</template>
        <template #disbursementDate="{ item }"> {{ formatDate(item.disbursement_date) }}</template>
        <template #transactionPeriod="{ item }"> {{ payPeriodOne(item) }}</template>
        <template #net="{ item }"> {{ item.amount | formatMoney("s") }}</template>
        <template #actions="{ item }"><a @click="openCheckFlyout(item)" @click.prevent>View</a></template>
      </data-table>
    </div>

    <div v-if="oneButtonSelected && oneDailyBreakup" class="custom-one-deposit-summary-table">
      <data-table :entries="dailyDeposits" :headers="depositSummaryHeaders" :loading="loader">
        <template #transactionPeriod="{ item }"> {{ formatDate(item.startDate) }}</template>
        <template #source="{ item }">{{ source(item.disbursement_src) }}</template>
        <template #net="{ item }"> {{ item.amount | formatMoney("s") }}</template>
        <template #status="{ item }">
          <span :title="item.pay_type == 'ADHOC' ? getTooltipData(item) : ''">
            {{ item.status }}
          </span>
        </template>
        <template #disbursementDate="{ item }"> {{ formatDate(item.disbursement_date) }}</template>
        <template #actions="{ item }"><a @click="openOneFlyout(item)" @click.prevent>View</a></template>
      </data-table>
      <h4 class="back-btn-week"><button class="button small" @click="backtoWeekly">Back to weekly</button></h4>
    </div>
    <div>
      <deposit-summary-flyout v-if="loadFlyout" name="deposit-summary" :details="depositSummaryFlyout"
        :hide-profile-btn="true"></deposit-summary-flyout>
    </div>
      <!-- <ach-deposit-summary-flyout name="ach-deposit-summary" :details="achFlyout" :mappingKey="mappingKey" :partnerID="partnerID"></ach-deposit-summary-flyout> -->
      <check-deposit-summary-flyout name="check-deposit-summary" :details="checkFlyout" :mappingKey="mappingKey" :partnerID="partnerID"></check-deposit-summary-flyout>
      <!-- <one-deposit-summary-flyout name="one-deposit-summary" :details="oneFlyout" :mappingKey="mappingKey" :partnerID="partnerID"></one-deposit-summary-flyout> -->
    <hold-deposit-summary-flyout name="hold-deposit-summary" :details="holdFlyout" :partnerID="partnerID"></hold-deposit-summary-flyout>
    <download-multiple-deposit-summary :downloadDepositSummary="downloadDepositSummary" @depositSummaryDownloaded="resetDownloadDepositSummary" :items="selectedSummaries"/>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import { restApi } from "@/http/http.config";
import ButtonBar from "@/components/buttons/ButtonBar";
import { encodeWithParam, reformatDepos } from "@/filters";
// import OneDaySummary from "../DownloadOneDeposits.vue";
// import VueHtml2pdf from "vue-html2pdf";
import DepositSummaryFlyout from "@/modules/admin/accounting/invoicing/DepositSummaryFlyout";
// import OneDepositSummaryFlyout from "@/modules/admin/accounting/invoicing/OneDepositSummaryFlyout";
// import AchDepositSummaryFlyout from "@/modules/admin/accounting/invoicing/AchDepositSummaryFlyout";
import CheckDepositSummaryFlyout from "@/modules/admin/accounting/invoicing/CheckDepositSummaryFlyout";
import HoldDepositSummaryFlyout from "@/modules/admin/accounting/invoicing/HoldDepositSummaryFlyout";
import DownloadMultipleDepositSummary from "@/modules/admin/accounting/invoicing/DownloadMultipleDepositSummary";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
// import { PDFDocument } from 'pdf-lib'

export default {
  name: "PartnerDepositSummaries",
  components: {
    Fragment,
    DataTable,
    DepositSummaryFlyout,
    ButtonBar,
    // OneDaySummary,
    // VueHtml2pdf,
    // OneDepositSummaryFlyout,
    // AchDepositSummaryFlyout,
    HoldDepositSummaryFlyout,
    CheckDepositSummaryFlyout,
    DownloadMultipleDepositSummary,
  },
  data() {
    return {
      htmlToPdfOptions: {
        filename: "",
        html2canvas: { useCORS: true, height: 1024, logging: false },
      },
      loader: true,
      selectedSummaries:[],
      achSummaries:[],
      oneSummaries:[],
      depositSummaries: [],
      oneDepositSummaries: [],
      achDepositSummaries: [],
      holdDepositSummaries: [],
      checkDepositSummaries: [],
      dailyDeposits: [],
      dayDeposits: {},
      depositSummaryFlyout: {},
      oneFlyout: {},
      achFlyout: {},
      holdFlyout: {},
      checkFlyout: {},
      locationName: "",
      totalCount: 0,
      perPage: 50,
      currentPage: 0,
      offset: 0,
      // oneTotalCount:0,
      onePerPage:50,
      oneCurrentPage:0,
      oneOffSet:0,
      selectedButton: "branch",
      oneDailyBreakup: false,
      // achDailyBreakup: false,
      downloadEnable: false,
      totalEarnings:0,
      downloadDepositSummary: false,
      mappingKey: {},
      userData:{},
      oneDepositSummariesBreakup:[]
    };
  },

  props: {
      partner: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
  computed: {
    ...mapGetters(["getPermission"]),
    partnerID() {
      return this.$route.params.id;
    },
    depositSummaryHeaders() {
      return [
        new TableHeader({ label: "Transaction period", headerClass: "cell25 text-left", name: "transactionPeriod",sortable: true,sortFunction: () => (item1, item2) => {
          if(item1.startDate){
            return new Date(item1.startDate) - new Date(item2.startDate);
          }
          else if(item1.settlement.startDate){
            return new Date(item1.settlement.startDate) - new Date(item2.settlement.startDate);
          }
          },display: true, direction: "desc" }),
        new TableHeader({ label: "Disbursement source", headerClass: "cell20 text-left", name: "source", cellContents: "disbursement_src" }),
        new TableHeader({ label: "Total Earnings (Net)", headerClass: "cell20", name: "net", cellContents: "netAmount" }),
        new TableHeader({ label: "Status", headerClass: "cell20", name: "status", cellContents: "status"}),
        new TableHeader({ label: "Disbursement date", headerClass: "cell25 text-left", name: "disbursementDate", cellContents: "disbursement_date" }),
        new TableHeader({ cellContents: "actions", headerClass: "cell20 text-right", name: "actions", cellClass: "table-actions" }),
      ];
    },
    depositSummaryOldHeaders() {
      return [
        new TableHeader({ label: "Transaction Period", headerClass: "cell25 text-left", name: "payPeriod", cellContents: "startDate", sortable: true, direction: "desc", display: true }),
        new TableHeader({ label: "Disbursement source", headerClass: "cell20 text-left", name: "source", cellContents: "disbursement_src" }),
        new TableHeader({ label: "Total Earnings (Net)", headerClass: "cell20 text-left", name: "net", cellContents: "netAmount" }),
        new TableHeader({ label: "Status", headerClass: "cell20 text-left", name: "status", cellContents: "status" }),
        new TableHeader({ cellContents: "actions", headerClass: "cell20 text-right", name: "actions", cellClass: "table-actions" }),
      ];
    },
    loadFlyout() {
      return Object.keys(this.depositSummaryFlyout).length > 0 ? true : false;
    },
    paymentProcessorButtons() {
      return [
        { id: "branch", label: "BRANCH" },
        { id: "one", label: "ONE" },
        { id: "ach", label: "ACH" },
        {id: "hold", label: "HOLD"},
        { id: "check", label: "CHECK" },

      ];
    },
    branchButtonSelected() {
      return this.selectedButton === "branch";
    },
    achButtonSelected(){
      return this.selectedButton === "ach";

    },
    oneButtonSelected() {
      return this.selectedButton === "one";
    },
    holdButtonSelected(){
      return this.selectedButton === "hold";

    },
    checkButtonSelected() {
      return this.selectedButton === "check";
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    formatDate(date) {
      return DateTime.fromISO(date).toFormat("dd LLL yyyy");
    },
    formatDateToUTC(date) {
      return DateTime.fromISO(date,{ zone: 'utc' }).toFormat("dd LLL yyyy");
    },

    source(value){
      if(value == 'DMS'){
        return 'REGULAR'
      }
      return value;
    },
    resetDownload(){
      let checkboxes=document.querySelectorAll('.checkbox-input-deposit');
      checkboxes.forEach((item)=>{
        item.checked=false;
      })
    },
    multiSelect(val, e){
      if(e.target.checked){
            val["payPeriod"] = this.payPeriodOne(val.settlement);
            if (val.id != this.selectedSummaries.map((item) => item.id) && this.selectedSummaries.length > 0) {
                this.selectedSummaries.push(val)
            }
            else{
              this.selectedSummaries.push(val)
            }
      }
      else{
        this.selectedSummaries.splice(this.selectedSummaries.indexOf(val.id), 1)
      }
    },

    achMultiSelect(val, e){
      if(e.target.checked){
            if (val.id != this.selectedSummaries.map((item) => item.id) && this.selectedSummaries.length > 0) {
              this.selectedSummaries.push(reformatDepos(val,this.mappingKey,this.userData))
            }
            else{
              this.selectedSummaries.push(reformatDepos(val,this.mappingKey,this.userData))
            }
      }
      else{
        this.selectedSummaries.splice(this.selectedSummaries.indexOf(val.id), 1)
      }
    },

    oneMultiSelect(val, e){
      if(e.target.checked){
            if (val.id != this.selectedSummaries.map((item) => item.id) && this.selectedSummaries.length > 0) {
              this.selectedSummaries.push(reformatDepos(val,this.mappingKey,this.userData))
            }
            else{
              this.selectedSummaries.push(reformatDepos(val,this.mappingKey,this.userData))
            }
      }
      else{
        this.selectedSummaries.splice(this.selectedSummaries.indexOf(val.id), 1)
      }
    },

    getTooltipData(item) {
      return item?.adhocPaymentDetails?.length ? `Created At: ${item?.adhocPaymentDetails[0]?.created_on} \nCreated By: ${item?.adhocPaymentDetails[0]?.created_by} \nInitiated At: ${item?.adhocPaymentDetails[0]?.initiated_on}\nInitiated By: ${item?.adhocPaymentDetails[0]?.initiated_by}\nReason Internal: ${item?.adhocPaymentDetails[0]?.reason_code}` : '';
    },

    getRemarks(item) {
      const result = item?.adhocPaymentDetails?.find((details) => details?.remarks?.trim()?.length > 0);
      return result?.remarks ?? '';
    },

    async oneMultiDownload(){
      this.downloadDepositSummary = true;
    },

  async achMultiDownload(){
    this.downloadDepositSummary = true;
  },
    async multiDownload(){
      this.downloadDepositSummary = true;
    },

    saveZip(filename, partnerName,data) {
        if (!data) return;
        const zip = new JSZip();
        const folder = zip.folder(`${this.$route.params.id}`);

        data.forEach((item) => {
        const blobPromise = fetch(item.url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
        });
        const name = `${partnerName}_${item.period}_${item.source}.pdf`;
        folder.file(name, blobPromise);
    });
        zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));

      },
    formatDatePacific(date) {
      return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("dd LLL yyyy")
    },
    openFlyout(item) {
      item["payPeriod"] = this.payPeriod(item);
      this.depositSummaryFlyout = item;
      this.showFlyout("deposit-summary");
    },
    openOneFlyout(item) {
      restApi.post(`/employee/getOneEarningsDayDetails`, encodeWithParam(
        { partnerID: this.partnerID, 
          disbursement_date: item.disbursement_date, 
          pay_type: item.pay_type,
          startDate:item.startDate,
          disbursement_src:item.disbursement_src 
        })).then(({ data }) => {
          item=reformatDepos(
            {oneBreakup:data.result,disbursement_date: item.disbursement_date,pay_type: item.pay_type, startDate:item.startDate,disbursement_src:item.disbursement_src,user:data.partnerInfo},
            this.mappingKey,this.userData)
            this.depositSummaryFlyout = item
            this.showFlyout("deposit-summary");
            // this.depositSummaryData(data.result, this.$props.details?.date, data.partnerInfo)
                })

    },
     openAchFlyout(item) {
      item=reformatDepos(item,this.mappingKey,this.userData)
      this.depositSummaryFlyout = item;
      this.showFlyout("deposit-summary");
    },
    openHoldFlyout(item) {
      item["locationName"] = this.$props.partner.location_name
      item["remarks"] = this.getRemarks(item);
      this.holdFlyout = item;
      this.showFlyout("hold-deposit-summary");
    },
    openCheckFlyout(item) {
      item["locationName"] = this.$props.partner.location_name;
      this.checkFlyout = item;
      this.showFlyout("check-deposit-summary");
    },
    payPeriod(item) {
      return `${this.formatDate(item.settlement.startDate)} - ${this.formatDate(item.settlement.endDate)}`;
    },
    payPeriodOne(item) {
      return `${this.formatDate(item.startDate)} - ${this.formatDate(item.endDate)}`;
    },
    // onPaginate(event) {
    //   this.currentPage = Number(event.pageNumber);
    //   this.offset = event.offset;
    //   this.loader = true;
    //   this.getPartnerDepos();
    // },
    // onOnePaginate(event) {
    //   this.oneCurrentPage = Number(event.pageNumber);
    //   this.oneOffSet = event.offset;
    //   this.loader = true;
    //   this.oneSummaries = [];
    //   this.oneBreakup();
    // },
    onChangeCount(event) {
      this.onePerPage = event.perPage;
    },
    async getPartnerDepos() {
      this.loader = true;
      this.oneDailyBreakup = false
      await restApi.post(`/partner/depositSummaries`, {
        "param": window.btoa(JSON.stringify({ id: this.partnerID }))
      })
        .then(({ data }) => {
          this.depositSummaries = data?.result.depositSummaries;
          this.totalCount = Number(data?.result.depositSummaries_aggregate);
          this.locationName = data?.result?.depositSummaries[0]?.clientLocation.name;
          this.loader = false;
        })
    },
    grossTotal(item) {
      return item?.check["BUSINESS_INCOME"]?.amount + item?.check["CREDITS"]?.amount;
    },
    buttonSelected(button) {
      this.loader = true;
      this.oneDailyBreakup = false
      this.oneDepositSummaries=[];
      this.achDepositSummaries=[];
      this.holdDepositSummaries=[]
      this.checkDepositSummaries=[];
      this.dailyDeposits = [];
      // this.achDailyBreakup = false
      this.selectedButton = button.id;
      switch (this.selectedButton) {
        case 'branch':
          this.getPartnerDepos();
          break;

        case 'one':
          this.fetchOneDepositSummaries();
          break;

          case 'ach':
          this.fetchAchDepositSummaries();
          break;

          case 'hold':
          this.fetchHoldDepositSummaries();
          break;

          case 'check':
          this.fetchCheckDepositSummaries();
          break;

      }
    },
    
    fetchOneDepositSummaries() {
      restApi.post(`/employee/oneDepositSummariesWeekly`, encodeWithParam({ partnerID: this.partnerID })).then((data) => {
        this.oneDepositSummaries = data.data.result;
        //this.oneTotalCount = Number(data?.data?.totalRecords);
        this.loader = false;
      })
    },

      fetchAchDepositSummaries() {
      this.oneDailyBreakup = false
      restApi.post(`/employee/achDepositSummariesWeekly`, encodeWithParam({ partnerID: this.partnerID })).then((data) => {
        if(data.data.success){
          // let cureentWeekMondayDate = this.getCurrentWeekMondayDate();
          // cureentWeekMondayDate = (cureentWeekMondayDate.toISOString()).substring(0,10);
          this.achDepositSummaries = data.data.result
          // .filter((obj) => {
          //   let startDate = obj?.startDate.substring(0,10);
          //   return new Date(startDate) < new Date(cureentWeekMondayDate);
          // });
        }
        this.loader = false;
      })
    },
    fetchHoldDepositSummaries(){
      this.oneDailyBreakup = false
      restApi.post(`/employee/holdDepositSummariesWeekly`, encodeWithParam({ partnerID: this.partnerID })).then((data) => {
        if(data.data.success){
          this.holdDepositSummaries = data.data.result;
        }
        this.loader = false;
      })

    },
    fetchCheckDepositSummaries() {
      this.oneDailyBreakup = false
      restApi.post(`/employee/checkDepositSummariesWeekly`, encodeWithParam({ partnerID: this.partnerID })).then((data) => {
        if(data.data.success){
          this.checkDepositSummaries = data.data?.result;
        }
        this.loader = false;
      })
    },
    oneBreakup(param) {
      this.loader = true;
      this.oneDailyBreakup = true;
      this.dailyDeposits=[]
      restApi.post(`/employee/oneDailyDepositSummaries`, encodeWithParam({ 
        partnerID: this.partnerID, 
        startDate: param.startDate, 
        endDate: param.endDate, 
        source: param.disbursement_src === 'REGULAR' ? 'DMS' : param.disbursement_src
      })).then((data) => {
        if(data.data.success){
          this.dailyDeposits = data.data.result;
        }
        this.loader = false;
      })
    },
    download(obj) {
      this.loader = true;
      restApi.post(`/employee/getOneEarningsDayDetails`, encodeWithParam({ partnerID: this.partnerID, selectedDate: obj.date })).then((data) => {
        this.depositSummaryData(data.data.result, obj, data.data.partnerInfo)
      })
    },
    depositSummaryData(depositResult, obj, partner) {
      let totalAmount = depositResult.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.totalAmount), 0);
      let credits = depositResult.map((item, index) => {
        return {
          id: index,
          name: this.mappingKey[item.mappingKey],
          amount: item.totalAmount,
          count: item.count
        }

      })
      depositResult.forEach(() => {
        let customObj = {
          netAmount: totalAmount,
          credits: credits,
          checkDate: obj.date,
          clientLocation: this.$props.partner.location_name,
          payPeriod: obj.date,
          firstName:partner.firstName,
          lastName:partner.lastName,
          partner: {
            name: `${partner.firstName} ${partner.lastName}`,
            email: partner.email,
          },
        };
        this.dayDeposits = customObj;
      });
      this.$refs.html2Pdf.generatePdf()
      this.loader = false;
    },

    enableDownload() {
      this.downloadEnable = true;
    },
    generateFileName() {
      this.htmlToPdfOptions.filename = `ONE-DAILY_${this.dayDeposits.firstName}_${this.dayDeposits.lastName}_DATE_${this.dayDeposits.checkDate}`;
    },
    backtoWeekly(){
      this.oneDailyBreakup=false;
      this.selectedButton = "one";
    },
    getCurrentWeekMondayDate() {
      const today = new Date();
      const day = today.getDay();
      const daysToMonday = (day || 7) - 1;
      const mondayDate = new Date(today.getTime() - daysToMonday * 24 * 60 * 60 * 1000);
      mondayDate.setHours(0);
      mondayDate.setMinutes(0);
      mondayDate.setSeconds(0);
      mondayDate.setMilliseconds(0);

      return mondayDate;
    },
    resetDownloadDepositSummary() {
      this.selectedSummaries=[];
      this.downloadDepositSummary = false;
      this.resetDownload()
    }
    //  backtoAchWeekly(){
    //   this.achDailyBreakup=false;
    //   this.selectedButton = "ach";
    // },
  },
  async mounted() {
    this.userData ={firstName:this.$props.partner.firstName,lastName:this.$props.partner.lastName};
    this.getPartnerDepos()
    restApi.post('/partner/getTotalEarningsPartner', encodeWithParam({ partnerID: this.partnerID }))
        .then(({ data }) => {
          this.totalEarnings = data?.result[0]?.totalearnings ? data.result[0].totalearnings:0;
        })

    const mapDetails = (await restApi.get("/employee/mappingKeyDetails"))?.data?.result.reduce((acc, item) =>  {
      const obj = { ...acc }
      obj[item.mappingKey] = item.name
      return obj
    },{})
    this.mappingKey = mapDetails;
  }
};
</script>
<style scoped>
.back-btn-week{
  text-align: end;
  margin-top: 20px;
}
.checkbox-input-deposit{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}
.annualEarnings{
            width: 400px;
            height: 80px;
            background-color:#f4f4f4;
            position: relative;
            text-align:center;
            margin-bottom: 10px;
            padding:8px;

}
.earningsAmount{
  color: green;
  font-size: 32px;
  text-align: center;
  width: 178px;
  height: 64px;
  background-color: #fff;
  position: absolute;
  display: grid;
  align-content: center;
  font-weight: 600;
}
.earnings-instructions {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0 !important;
  }
  ul{
    list-style: unset;
  }
.earningsText{ 
  
            width: 191px;
            height: 64px;
            position: absolute;
            left: 200px;
            padding :10px 5px 10px 5px;
            text-align:center;
            list-style-type: none; 

 } 
</style>
<style>
.custom-one-deposit-summary-table .paginator:nth-child(2) {
  display: none;
}
</style>